<template>
  <div>
    <div
      v-if="form.platformType == 2"
      class="Container"
      :style="`background-image:url(${form.backdropUrl});background-size: cover;background-repeat: no-repeat;`"
    >
      <div class="top-block">
        <img :src="form.logoUrl" mode="widthFix" class="logo" />
      </div>
      <div style="margin-top: 3rem">
        <img :src="form.wechatCard" class="codema" />
      </div>
      <!-- <wx-open-launch-weapp
        id="launch-btn"
        :appid="form.appId"
        :username="form.page"
        :path="`pages/index/index?appfwcode=${fwCode}`"
      >
        <script type="text/wxtag-template">
          <div style="font-size: 14px;
              color: rgb(11, 11, 143);
              height:28px;
              text-decoration: underline;">
            或直接打开小程序查询
          </div>
        </script>
      </wx-open-launch-weapp> -->
    </div>
    <div
      v-if="form.platformType == 1"
      class="Container"
      :style="`background-image:url(${form.backdropUrl});background-size: cover;background-repeat: no-repeat;`"
    >
      <div class="top-block">
        <img :src="form.logoUrl" mode="widthFix" class="logo" />
      </div>
      <div class="body">
        <img src="../assets/skinoImg.png" alt="" style="width: 100%" />
        <img :src="form.wechatCard" class="codema" style="margin-top: 1.7rem" />
      </div>
    </div>
  </div>
</template>
<script>
import "./flexible";
import { getPlatform, getAWxJsApi } from "@/api/index";
export default {
  data() {
    return {
      fwCode: "",
      ISdata: {},
      form: {},
    };
  },
  components: {},
  created() {},
  async mounted() {
    this.fwCode = window.location.search.split("=")[1];
    const param = {
      code: window.location.search.split("=")[1],
    };
    const res = await getPlatform(param);

    if (res.code === 200) {
      this.form = res.data;
      const oScript = document.createElement("script");
      oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
      oScript.type = "text/javascript";
      oScript.onload = this.wxmini;
      document.body.appendChild(oScript);
    }
  },
  methods: {
    async wxmini() {
      const result = await getAWxJsApi({
        url: window.location.href.split("#")[0],
        appId: this.form.wxAppId,
      });

      this.ISdata = result.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: this.form.wxAppId, // 必填，公众号的唯一标识
        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
        signature: result.data.signature, // 必填，签名
        jsApiList: [
          "getLocalIPAddress",
          "getSystemInfo",
          "chooseImage",
          "previewImage",
        ], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      wx.ready(function (res) {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        // alert(res);
      });
    },
  },
};
</script>

<style scoped>
.logo {
  width: 4rem;
  margin-top: 2rem;
}
.Container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  /* font-size: 0; */
  min-height: 100vh;
  padding-left: 0px;
}
.top-guar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.bottom-guar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.tip {
  width: 100%;
  font-size: 0.8rem;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  margin-top: 0.2rem;
}
.codema {
  width: 34%;
  margin-top: 4.2rem;
}

.smallbody {
  margin-top: 2.3rem;
  font-size: 0.5rem;
  color: #776a6b;
  font-family: FZLanTingHei-DB-GBK;
}
</style>
