import VueRouter from "vue-router";
import index from "../pages/index.vue";
import Gzh from "../pages/Gzh.vue";
import skino from "../pages/skino.vue";
import carrierBodorme from "../pages/carrier/bodorme.vue";

// 创建并暴露一个路由器
export default new VueRouter({
  // mode: "history",
  routes: [
    {
      path: "/",
      component: index,
    },
    {
      path: "/Gzh",
      name: "Gzh",
      component: Gzh,
    },
    {
      path: "/skino",
      name: "skino",
      component: skino,
    },
    {
      path: "/carrier/bodorme",
      name: "skino",
      component: carrierBodorme,
    },
  ],
});
