import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css'

// Vue.use(Vant)
Vue.use(VueRouter)

Vue.config.productionTip = false
// Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.config.ignoredElements = [...Vue.config.ignoredElements, 'wx-open-launch-weapp']

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
