<template>
  <div
    class="container"
    :style="`background-image:url(${form.backdropUrl});background-size: cover;background-repeat: no-repeat;`"
  >
    <div class="top-block">
      <img :src="form.logoUrl" mode="widthFix" class="logo" />
    </div>
    <!-- 首次查询 -->
    <div
      v-if="dataForm.firstQuery"
      class="resBox"
      style="margin-top: 0.7rem; font-size: 0.5rem; line-height: 1rem"
    >
      <div class="resTitle">
        <div style="font-size: 0.66rem">防伪查询结果</div>
        <div style="font-size: 0.4rem; position: relative; top: -0.2rem">
          Anti-counterfeiting query results
        </div>
      </div>

      <div v-html="dataForm.firstPromptsContent" class="fwText"></div>

      <div class="goodsText">
        <div>品名：{{ dataForm.traceGoods.goodsName }}</div>
        <div>规格： {{ dataForm.traceGoods.goodsPackagingSpec }}</div>
        <div style="margin-top: 0.8rem">
          如与您的首次查询时间不符，请当心该产品是假冒产品，如有疑问请与"肌漾"微信公众平台客服联系。
        </div>
      </div>
    </div>
    <!-- 二次查询 -->
    <div
      v-if="!dataForm.firstQuery && !noMessage"
      class="resBox"
      style="margin-top: 0.7rem; font-size: 0.5rem; line-height: 1rem"
    >
      <div class="resTitle">
        <div style="font-size: 0.66rem">防伪查询结果</div>
        <div style="font-size: 0.4rem; position: relative; top: -0.2rem">
          Anti-counterfeiting query results
        </div>
      </div>

      <div v-html="dataForm.notFirstPromptsContent" class="fwText"></div>
      <!-- <div v-html="ccc" class="fwText"></div> -->

      <div class="goodsText">
        <div>品名：{{ dataForm.traceGoods.goodsName }}</div>
        <div>规格： {{ dataForm.traceGoods.goodsPackagingSpec }}</div>
        <div style="margin-top: 0.8rem">
          如与您的首次查询时间不符，请当心该产品是假冒产品，如有疑问请与"肌漾"微信公众平台客服联系。
        </div>
      </div>
    </div>
    <!-- 查询不到 -->
    <div
      v-if="noMessage"
      class="resBox"
      style="margin-top: 1rem; font-size: 0.5rem; line-height: 1rem"
    >
      <div class="resTitle">
        <div style="font-size: 0.66rem">防伪查询结果</div>
        <div style="font-size: 0.4rem; position: relative; top: -0.2rem">
          Anti-counterfeiting query results
        </div>
      </div>
      <div class="goodsText" style="margin-top: 0.5rem">
        {{ noMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { getruleByfwcode, verifyGetRule } from "@/api/index";
export default {
  data() {
    return {
      // ccc: '<p class="ql-align-center"><span style="color: rgb(0, 176, 80);">恭喜，您查询的是本公司正品！</span></p><p class="ql-align-center">防伪码：{code}</p><p class="ql-align-center">  查询次数：{hits}</p><p class="ql-align-center">首次查询时间：&nbsp;{firstQueryTime}</p>',
      form: {},
      dataForm: {},
      fwCode: "",
      browser: "",
      ipAddress: "",
      noMessage: "",
    };
  },
  mounted() {
    //  初始化请求公众号参数
    this.getList();
    this.postInfo(this.$route.query.res1);
  },
  methods: {
    async getList() {
      const result2 = await verifyGetRule({ appId: "wx8701704f2499ff42" });
      this.form = result2.data;
    },

    //  扫描二维码发送请求
    async postInfo(Iscode) {
      const param = {
        browser: this.browser,
        ipAddress: this.ipAddress,
        code: Iscode,
      };

      try {
        const res = await getruleByfwcode(param);

        if (res.code == "200") {
          this.dataForm = res.data;
        } else {
          this.noMessage = res.msg;
        }
      } catch (error) {
        console.log(error, "rrrerror");
      }
    },
  },
};
</script>

<style scoped>
.centerToast {
  z-index: 100000000001 !important;
}

.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  background: #90aeca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  width: 4rem;
  margin-top: 1.2rem;
}
.tip {
  width: 100%;
  font-size: 0.6rem;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-top: 1rem;
}
button {
  border-radius: 0px;
  width: 70%;
  margin: 0;
  padding: 0;
}

.resBox {
  /* margin-top: .5rem; */
  width: 68%;
  border-radius: 0.7rem;
  padding: 1rem 0.5rem 0.6rem 0.5rem;
  position: relative;
  background-color: rgb(218, 228, 238, 0.55);
  text-align: left;
  box-shadow: 0px 0px 0.9375rem rgba(180, 180, 180, 0.5);
}
.resTitle {
  height: 3rem;
  position: absolute;
  top: -0.5rem;
  z-index: 999;
  left: 0.88rem;
  text-align: center;
  font-family: PingFang SC;
}
.fwText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
}

.fwText :nth-child(1) {
  text-align: center;
  margin-top: 0.38rem;
  margin-bottom: 0.28rem;
}
.goodsText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
  margin-top: 0.8rem;
}
</style>
